import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, catchError, filter } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  contactapiurl:string = 'https://nbo7so17s1.execute-api.us-east-1.amazonaws.com/dev/api/form';

  constructor(private http: HttpClient, private route: Router,) { }

  contactform(formdatas:any, Headers:any)
  {
    console.log(formdatas);
    const headers = { 'Content-Type': 'application/json'}
    return this.http.post(this.contactapiurl, formdatas, {'headers':headers}).pipe(map((data:any)=> {
      console.log(data)
      return data;
    }),
    catchError(error => {
      return throwError (error);
    }));
  }
}
