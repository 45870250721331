<header id="myHeader" class="blog-header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg">
                    <a class="navbar-brand" href="index.html">
                      <img src="assets/images/logo.png" class="logo-light" alt="logo">
                    </a>
                    <button class="navbar-toggler" type="button">
                      <span class="menu-lines"><span></span></span>
                    </button>
                    <div class="collapse navbar-collapse" id="mainNavigation">
                      <ul class="navbar-nav ml-auto">
                        <li class="nav__item">
                          <a href="" data-toggle="dropdown" class="nav__item-link blog-menu">Home</a>
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#about" data-toggle="dropdown" class="nav__item-link blog-menu">About</a>
                          
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#WhyKevells" data-toggle="dropdown" class="nav__item-link blog-menu">Why Kevells</a>
                          
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#Features" data-toggle="dropdown" class="nav__item-link blog-menu">Features</a>
                          
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#Solution" data-toggle="dropdown" class="nav__item-link blog-menu">Solution</a>
                          
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                            <a [routerLink]="['/blogs']" data-toggle="dropdown" class="nav__item-link active blog-menu">Blog</a>
                            
                          </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#Contact" class="nav__item-link blog-menu">Contact</a>
                        </li><!-- /.nav-item -->
                      </ul><!-- /.navbar-nav -->
                      <button class="close-mobile-menu d-block d-lg-none"><i class="fas fa-times"></i></button>
                    </div><!-- /.navbar-collapse -->
                </nav><!-- /.navabr -->
            </div>
        </div>
    </div>
    
  </header><!-- /.Header -->
<div class="privacy-policy-section">
    <div class="container">
    <div class="row">
        <div class="col-lg-12">
        <h4>Your privacy is important to us</h4>
        <p>Kevell Corp is located at:</p>
        <p>Kevell Corp</p>
        <p>#42, First floor, Kakkan Street, Shenoy Nagar, Goripalayam Madurai</p>
        <p>625020 - Tamil Nadu , India</p>
        <h6>GENERAL WARRANTY TERMS AND CONDITIONS</h6>
        <p>Smart Home Device, the owner of brand Kevell Corp, here in after referred to as the Guarantor, shall guarantee good quality and proper functioning of the purchased device if used in the manner appropriate for the purpose thereof and in accordance with the operation manual, and shall provide warranty services subject to the following conditions:</p>
        
        <div class="text-data">
        <ul>
        <li>1 This Warranty covers the defects resulting from defective parts, materials or manufacturing, if   
        such defects are revealed during the period of 12 months since the date of purchase.</li>
        <li>2 The device is considered defective if it fails to perform the functions as indicated in the operation
        manuals, technical specifications or any other similar documents supplied with the equipment, and the  
        failure is due to internal device characteristics.</li>
        <li>3 The Warranty does not cover any software by third party manufacturers or vendors, that is installed on   
        the device under repair and delivered along with the device to the Guarantor Service Center.</li>
        <li>4 The Guarantor shall not be held responsible for the loss and restoration of any software or data.</li>
        <li>5 The defects and damages revealed during the Warranty period shall be removed free of charge  
        exclusively by the Guarantor Service Center located in #42, First Floor, Kakkan Street, Shenoy Nagar,
        Goripalayam, Tamil Nadu Madurai 625020, within 14 working days since the date of delivering the
        device to the Service Center.</li>
        <li>6 Warranty services will be provided under the following conditions:</li>
        <div class="netsted-data">
        <ul>
        <li>* Immediately and effectively notify Guarantor about determined device’s defects and cease any use of it.</li>
        <li>* The marked device along with a copy of the commercial invoice should be delivered to the Guarantor
        Service Center.</li>
        <li>* The serial number of The delivered defective device must match the serial number sold to the Customer.</li>
        <li>* The Guarantor Service Center should be notified in writing of any additional accessories installed in the
        device.</li>
        <li>* The device should be delivered together with a detailed and readable description of the technical
        problem.</li>
        <li>* The delivered device should have intact seals with production date or serial numbers of the Guarantor or
        the Manufacturer and should be appropriately packaged during its loading, transportation and
        unloading.</li>
        <li>* The shipment to the Guarantor Service Center must be arranged and paid by the Customer.</li> 
        </ul>
        </div>
        <li>7 The Warranty does not cover:</li>
        <div class="netsted-data">
        <ul>
        <li>* Mechanical or electric damages resulting from incorrect installation, configuration, usage or other
        activities inconsistent with the operation manual or contradictory to technical specifications attached to   
        the device</li>
        
        <li>* Damages floods, fires, lighting or other natural disasters, wars, unexpected events, inappropriate
        voltage, defective supply materials or other external factors</li>
        
        <li>* The device that has been tampered with by the Warranty beneficiary or any other person in anyway,
        including reconfiguration, repair, willful constructional variations, modifications and adjustments</li>
        
        <li>* The device with serial numbers and / or the Guarantor seals damaged or illegible</li>
        
        <li>* The activities specified in the operation manual, which remain the sole responsibility of the Customer in
        his own capacity and at his own expense</li>
        
        <li>* Defects resulting from the usage of improper or non-genuine supply materials</li>
        
        <li>* Damages due to the user's fault or lack of knowledge</li>
        
        <li>* Defective functioning of the device caused by a conflict or incompatibility between software
        applications installed on the damaged device or on the equipment, which the device permanently
        cooperates in accordance with the intended purpose of the device.</li>
        </ul>
        </div>
        <li>8 The Guarantor reserves the right to replace the defective device or its component with a free-of-defects  
        equivalent thereof, provided that such equivalent ensures efficiency and functionality equal to or higher
        than the original device or component. The replaced defective device  or components shall become the
        property of the Guarantor.</li>
        
        <li>9 The Warranty period is extended by the time during which the repaired device stays in the Guarantor's
        Service Center.</li>
        
        <li>10 The Warranty period is extended by the time during which the repaired device stays in the Guarantor's
        Service Center.</li>
        
        <li>11 The Guarantor reserves the right to refuse to provide any Warranty services if it would result in a
        breach of applicable laws.</li>
        
        <li>12 The rights granted by this Warranty shall not include the right of the Warranty beneficiary to claim any
        lost profits in connection with defects of the device. The Guarantor shall not be held responsible for
        any material losses caused by the defective product.</li>
        </ul>
        </div>
            </div>
        
        </div>
    </div>
    </div>

    <footer class="footer-bg">
        <div class="image-layer">
            <figure class="image-1 rotate-me"><img src="assets/images/wheel.svg" alt=""></figure>
        </div>
            <div class="container footer-position">
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <a href="index.html" class="footer-logo" data-aos="fade-up" data-aos-duration="300"><img src="assets/images/logo.png" alt="kzeus" class="img-size"></a>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <ul class="footer-ul" data-aos="fade-up" data-aos-duration="600">
							<li class="footer-li"><a href="https://www.facebook.com/kzeusKIoT" target="_blank"><i class="fab fa-facebook-f footer-li-1"></i></a>
							</li>
							<li class="footer-li"><a href="https://twitter.com/KevellsKzeus" target="_blank"><i class="fab fa-twitter footer-li-2"></i></a>
							</li>
							<li class="footer-li"><a href="https://www.instagram.com/kzeuskevells/" target="_blank"><i class="fab fa-instagram footer-li-3"></i></a>
							</li>
							<li class="footer-li"><a href="https://www.linkedin.com/company/73017400" target="_blank"><i class="fab fa-linkedin-in footer-li-4"></i></a>
							</li>
							<li class="footer-li"><a href="https://in.pinterest.com/kzeuskevells/" target="_blank"><i class="fab fa-pinterest-p footer-li-5"></i></a>
							</li>
						</ul>
                    </div>
                </div>
                <div class="footer-list-ul">
                    <div class="row">
                        <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
                            <h3>Solutions</h3>
                            <ul class="li-color">
                                <li><a href="javascript:void(0)">Smart Building</a></li>
                                <li><a href="javascript:void(0)">Automation</a></li>
                                <li><a href="javascript:void(0)">Home Automation</a></li>
                                <li><a href="javascript:void(0)">Agriculture</a></li>
                                <li><a href="javascript:void(0)">Hospitality</a></li>
                                <li><a href="javascript:void(0)">Health</a></li>
                                <li><a href="javascript:void(0)">Industry</a></li>
                            </ul>
                        </div>
                        <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1300">
                            <h3>Contact Us</h3>
                            <h4>Customer support</h4>
                            <ul class="li-color">
                                <li><a href="mailto:info@kzeus.io">info@kzeus.io</a></li>
                                <li><a href="mailto:support@kzeus.io">support@kzeus.io</a></li>
                            </ul>
                            <h4>Business development</h4>
                            <ul class="li-color">
                                <li><a href="mailto:sales@kzeus.io">sales@kzeus.io</a></li>
                                <li><a href="mailto:service@kzeus.io">service@kzeus.io</a></li>
                            </ul>
                            <h4>Hot Line</h4>
                            <ul class="li-color">
                                <li><a href="javascript:void(0)">+91 70102 12129</a></li>
                            </ul>
                        </div>
                        <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1600">
                            <h3>Locations</h3>
                            <ul class="loc-pad">
                                <li class="flag-ind"><img src="assets/images/india.svg">
                                    <h4>India</h4>
                                </li>
                                <li>5th Street, Poriyalar Nagar,</li>
								<li>Tiruppalai, City Union Bank 1st Floor,</li>
								<li>Madurai, Tamil Nadu 625014.</li>
                            </ul>
                            <ul class="loc-pad">
                                <li>3A, CG2 Ashwin Adisaya flats,</li>
                                <li>Ponnurangam street,</li>
                                <li>Puzhuthivakkam, Chennai-600091.</li>
                            </ul>
                            <ul class="loc-pad">
                                <li>UpStart Co-working Space,</li>
                                <li>14/3A 1A4 OPP Brigade Cosmopolis,</li>
                                <li>Balaraj's Arcade, 4th Floor,</li>
                                <li>Whitefield Main Road,</li>
                                <li>Whitefield, Bangalore, Karnataka - 560066.</li>
                            </ul>
                        </div>
                        <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1900">
                            <ul class="loc-pad loc-mar">
                                <li class="flag-us"><img src="assets/images/united-states.svg">
                                    <h4>United States</h4>
                                </li>
                                <li>10295 Taliesin Dr #305,</li>
                                <li>Englewood, Colorado 80112.</li>
                            </ul>
                            <ul class="loc-pad">
                                <li class="flag-sing"><img src="assets/images/singapore.svg">
                                    <h4>Singapore</h4>
                                </li>
                                <li>1 Coleman street #10-06</li>
                                <li>The Adelphi Singapore 179803.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="footer-copyrights">
                    <p>Copyright &copy; 2022 <a href="index.html" class="rights-link">Kevells</a>. All rights reserved</p>
                    <p><a [routerLink]="['/privacypolicy']" class="rights-link">Privacy policy</a></p>
                </div>
                <!-- <a id="#top" onclick="scrollToTop()" class="top-position">
                    <i class="fas fa-chevron-up icon-top"></i>
                    <label>Top</label>
                </a> -->
                <!-- Back to top button -->
                <a href="#" id="scroll" style="display: none;">
                    <i class="fas fa-chevron-up icon-top"></i>
                    <label>Top</label>
                </a>
            </div>
        <div class="footer-shape" style="background-image: url(assets/images/footer-btm.svg);"></div>
    </footer>
