import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kzeus';
  isShow!: boolean;
  topPosToStartShowing = 100;


  constructor(private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
   }

   @HostListener('window:scroll')

   checkScroll() {
   const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
   console.log('[scroll]', scrollPosition);
   if (scrollPosition >= this.topPosToStartShowing) {
     this.isShow = true;
   } else {
     this.isShow = false;
   }
 }

  

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
}
