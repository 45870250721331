import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/api-service.service';
import { Slick } from 'ngx-slickjs';

@Component({
  selector: 'app-component',
  templateUrl: './component.component.html',
  styleUrls: ['./component.component.scss']
})
export class ComponentComponent implements OnInit {

  registerForm: any;
  contactstatus: any;
  conformsuccess: any;
  submitted: any;
  conformsuccess1: any;

  
  constructor(private formBuilder: FormBuilder, private servicecontact: ApiServiceService,) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
      {
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        companyname: ['', Validators.required],
        phonenumber: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10)]],
        description: ['', Validators.required],
      }
    );
  }

  get f() 
  {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid)
    {
      return
    }

    let myName = this.registerForm.get('name')?.value;
    let myEmail = this.registerForm.get('email')?.value;
    let myNumber = this.registerForm.get('phonenumber')?.value;
    let myCompany = this.registerForm.get('companyname')?.value;
    let myMessage = this.registerForm.get('description')?.value;


    const formObjs = {
      name: myName,
      email: myEmail,
      phoneNumber: myNumber,
      company:myCompany,
      message:myMessage
    }

    const formvalss = JSON.stringify(formObjs);
    console.log(formvalss);

    this.servicecontact.contactform(formvalss, Headers).subscribe((data:any) => {
      console.log(data);
      this.contactstatus = data;
      console.log('conaaaa', this.contactstatus);
      this.conformsuccess = 'Thanks for contacting us! We will get back to you soon..';
        setTimeout(()=>{
          this.conformsuccess = '';
          location.reload();
        }, 2000);
     }, (error) => {
      if (error.status !== 200) {
        this.conformsuccess1 = 'Something went wrong!';
      }
      console.log('contacterr', error.response);
    }
    )

  }

  arrayLength = 10;
  config: Slick.Config = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 2,
      dots: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
  
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
  
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 991.98,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
  
      ] 
    }

  
    public getImage: any = [
      {
        imgSrc: "assets/Img/logo-design.svg",
        content : "Logo Design"
      },
      {
        imgSrc : "assets/Img/flyer-design.svg",
        content : "Flyer Design"
      },
      {
        imgSrc : "assets/Img/business-card -design.svg",
        content : "Business Card Design"
      },
      {
        imgSrc : "assets/Img/graphics-Letter-head.svg",
        content : "Letterhead Design"
      },
      {
        imgSrc : "assets/Img/signage-design.svg",
        content : "Signage Design"
      },
      {
        imgSrc : "assets/Img/billboard-design.svg",
        content : "Billboard Design"
      },
      {
        imgSrc : "assets/Img/Webdesign.svg",
        content : "Web Design"
      },
      {
        imgSrc : "assets/Img/banner-add-design.svg",
        content : "Banner ad Design"
      },
      {
        imgSrc : "assets/Img/icon-design.svg",
        content : "Icon Design"
      },
      {
        imgSrc : "assets/Img/email-market.svg",
        content : "Email Marketing Design"
      },
      {
        imgSrc : "assets/Img/magazine-design.svg",
        content : "Magazine Design"
      },
      {
        imgSrc : "assets/Img/Menu.svg",
        content : "Menu Design"
      },
      {
        imgSrc : "assets/Img/sticker-design.svg",
        content : "Sticker Design"
      },
      {
        imgSrc : "assets/Img/news-page-add-design.svg",
        content : "Newspaper ad Design"
      },
      
    ]
}
