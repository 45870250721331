import { Component, OnInit } from '@angular/core';
import bloginfo from '../../../assets/blogs.json';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  BlogDetails: any = bloginfo;
  id: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.BlogDetails)
  }

}
