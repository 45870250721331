<div id="home" class="header-section header">
    <header id="myHeader">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="index.html">
                          <img src="assets/images/logo.png" class="logo-light" alt="logo">
                        </a>
                        <button class="navbar-toggler" type="button">
                          <span class="menu-lines"><span></span></span>
                        </button>
                        <div class="collapse navbar-collapse" id="mainNavigation">
                          <ul class="navbar-nav ml-auto">
                            <li class="nav__item">
                              <a href="#home"  class="nav__item-link">Home</a>
                            </li><!-- /.nav-item -->
                            <li class="nav__item">
                              <a href="#about" class="nav__item-link">About</a>
                              
                            </li><!-- /.nav-item -->
                            <li class="nav__item">
                              <a href="#WhyKzeus" class="nav__item-link">Why Kzeus</a>
                              
                            </li><!-- /.nav-item -->
                            <li class="nav__item">
                              <a href="#Features" class="nav__item-link">Features</a>
                              
                            </li><!-- /.nav-item -->
                            <li class="nav__item">
                              <a href="#Solution" class="nav__item-link">Solution</a>
                              
                            </li><!-- /.nav-item -->
                            <li class="nav__item">
                                <a [routerLink]="['/blogs']" class="nav__item-link">Blog</a>
                                
                              </li><!-- /.nav-item -->
                            <li class="nav__item">
                              <a href="#Contact" class="nav__item-link">Contact</a>
                            </li><!-- /.nav-item -->
                          </ul><!-- /.navbar-nav -->
                          <button class="close-mobile-menu d-block d-lg-none"><i class="fas fa-times"></i></button>
                        </div><!-- /.navbar-collapse -->
                    </nav><!-- /.navabr -->
                </div>
            </div>
        </div>
        
      </header><!-- /.Header -->
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="Iot-define">
                    <h3>What does IoT<br> Mean for Business?</h3>
                    <p>IoT is Rapidly changing the expectations of consumers. The IoT offers businesses
                        unprecedented oppurtunities for gathering data and automating the process in many sectors.
                        It also offers businesses the oppurtunity of developing new products or services. </p>
                    <a class="iot-button" href="#contactnow">Contact Now</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <img class="product-pic" src="assets/images/picture.png">
            </div>
        </div>
    </div>
</div>
<div class="bg-middle page-section" id="about">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="middle-content">
                    <h3 data-aos="fade-up" data-aos-duration="0"> Everything you need to know about Kzeus IoT product</h3>
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                            <p class="middle-detail" data-aos="fade-up" data-aos-duration="200">People find many IoT products which are quality in different genres because they
                                make use of their skills, and there's nothing that reveals the rewarding satisifaction people get when
                                they actually figure out the correct beneficial solution, even if takes some time for a while. When
                                people saw Kzeus IoT products on the global market, they were pulled to buy it due to the fact that it
                                actively involved several salient features but it was also like nothing they have used before.</p>
                        </div>
                    </div>
                    
                    <div class="ullogo">
                        <ul class="logo-inline">
                            <li data-aos="fade-up" data-aos-duration="500"><img src="assets/images/office-building.png" class="build-img">
                                <p>Smart Building</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="800"><img src="assets/images/machinery.png" class="auto-img">
                                <p>Automation</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200"><img src="assets/images/smart-home.png" class="homeauto-img">
                                <p>Home Automation</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500"><img src="assets/images/agronomy.png" class="agri-img">
                                <p>Agriculture</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1800"><img src="assets/images/hotel.png" class="hosp-img">
                                <p>Hospitality</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="2100"><img src="assets/images/robotic-surgery.png" class="health-img">
                                <p>Health</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="2400"><img src="assets/images/factory.png" class="indus-img">
                                <p>Industry</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="video-bg">
                    <img src="assets/images/video_bg.png" class="videoimage">
                    <a href="javascript:void(0)" class="playicon" onclick="toggle()">
                        <i class="fa fa-play play-design" aria-hidden="true"></i>
                        <span class="ripple"></span>
                    </a>
                    <div class="popupvideo">
                        <video controls="true">
                            <source src="assets/video/kzeus.mp4" type="video/mp4">
                        </video>
                        <img src="assets/images/closeimg1.webp" class="close" onclick="closeToggle()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="whykev page-section" id="WhyKzeus">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-5 col-sm-12 kevells-worldwide">
                <h3 class="bottomline-whykev" data-aos="fade-up" data-aos-duration="300">Why choose Kzeus</h3>
                <p class="margin-bottom" data-aos="fade-up" data-aos-duration="600">Building own IOT product from Scratch by understanding customer
                    requirements, go through research and give a Hassle-free solution on satisfactory level.</p>
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                        <div class="why-item" data-aos="fade-up" data-aos-duration="800">
                            <img src="assets/images/india_1.svg" class="india">
                            <p>Our India, Our pride. Join us and support make in india</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                        <div class="why-item" data-aos="fade-up" data-aos-duration="1000">
                            <img src="assets/images/global-1.svg" class="global">
                            <p>Wide ecosystem of hardware and software products</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                        <div class="why-item" data-aos="fade-up" data-aos-duration="1200">
                            <img src="assets/images/cpu.svg" class="cpu">
                            <p>Best quality hardware your customers rely on</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                        <div class="why-item" data-aos="fade-up" data-aos-duration="1400">
                            <img src="assets/images/teamwork.svg" class="teamwk">
                            <p>Hassle-free development of custom hardware with our expert team</p>
                        </div>
                    </div>
                </div>
               </div>
                
            <div class="col-md-6 col-lg-7 col-sm-12 mobile-bg">
                <img data-aos="fade-up" data-aos-duration="1000" src="assets/images/why-choose.png" class="mobile-img">
            </div>
        </div>
    </div>
    <div class="container" id="Features">
        <div class="attfets">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                    <h3 class="margin-70 bottomline-attractive" data-aos="fade-up" data-aos-duration="300"><strong>Attractive Features</strong></h3>
                    <p class="margin-bottom-p" data-aos="fade-up" data-aos-duration="600">Kzeus is enabled for 6 channel switch with attractive features listed below.
                        We are working on it to enhance more in future.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12">
                <div class="card-content" data-aos="fade-up" data-aos-duration="900">
                    <div class="smart"><img src="assets/images/smart-house.svg" style="width: 60px;"></div>
                    <div class="smart-1">
                        <h3>Easy Appliance Control</h3>
                        <p>Our connect your home appliances with our product, configure it through the App, Turn it
                            On/OFF from your mobile APP from any where.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
                <div class="card-content" data-aos="fade-up" data-aos-duration="1200">
                    <div class="smart"><img src="assets/images/air-conditioner.svg" style="width: 60px;"></div>
                    <div class="smart-1">
                        <h3>High Wattage Appliance</h3>
                        <p>We have a separate channel for high wattage appliances (e.g.:AC,Geyser..)control it from
                            the App.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
                <div class="card-content" data-aos="fade-up" data-aos-duration="1500">
                    <div class="smart"><img src="assets/images/gas-pipe.svg" style="width: 60px;"></div>
                    <div class="smart-1">
                        <h3>Gas-Leakage Alert System</h3>
                        <p>Thinking about safety issue in kitchen, the device will detect LPG leakage and automatic
                            Gas flow will cut-off with sound alert and mobile notification in the App.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
                <div class="card-content" data-aos="fade-up" data-aos-duration="1800">
                    <div class="smart"><img src="assets/images/dashboard.svg" style="width: 60px;"></div>
                    <div class="smart-1">
                        <h3>Sensor Data Monitoring</h3>
                        <p>In the Dashboard live temperature and humidity will show for individual room or device
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
                <div class="card-content" data-aos="fade-up" data-aos-duration="2100">
                    <div class="smart"><img src="assets/images/power.svg" style="width: 60px;"></div>
                    <div class="smart-1">
                        <h3>Power Monitoring</h3>
                        <p>How much power is consuming for the total appliances connected with this device that live
                            data in WATT will show on dashboard</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
                <div class="card-content" data-aos="fade-up" data-aos-duration="2400">
                    <div class="smart"><img src="assets/images/fan.svg" style="width: 60px;"></div>
                    <div class="smart-1">
                        <h3>Dimmer/Regulator Control</h3>
                        <p>We have a separate channel to connect fan or filament bulb to regulate the speed or
                            brightness</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-sm-12">
                <div class="card-content-pixel" data-aos="fade-up" data-aos-duration="2700">
                    <div class="smart"><img src="assets/images/color-palette.svg" style="width: 60px;"></div>
                    <div class="smart-1">
                        <h3>Pixel Color LED Control</h3>
                        <p>Now our device is capable to pick up any color of Neo pixel led and change it any time
                            depending on your mood.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- <section class="product-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                <h3 class="margin-70 bottomline-attractive text-center" data-aos="fade-up" data-aos-duration="300"><strong>Our Products</strong></h3>
                <p class="margin-bottom-p" data-aos="fade-up" data-aos-duration="600">Kzeus is enabled for 6 channel switch with attractive features listed below.
                    We are working on it to enhance more in future.
                </p>
            </div>
            <div class="product-slider">
                <div slickContainer #slickController="slick" [slickConfig]="config" class="sliders">
                    <ng-container *ngIf="slickController.initialize">
                        <div slickItem *ngFor="let item of getImage; let index = index" class="slider">
                            <div>
                                <img [src]="item.imgSrc" class="slider-img h-100">
                                <p class="slider-content">{{item.content}}</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="owl-carousel">
                    <div class="item product-item">
                        <div class="product-pic-wrapper">
                            <img src="../../../assets/images/products/home-automation.png" alt="">
                        </div>
                        <h3><a>Kzeus Home automation</a></h3>
                    </div>
                    <div class="item product-item">
                        <div class="product-pic-wrapper">
                            <img src="../../../assets/images/products/home-automation.png" alt="">
                        </div>
                        <h3><a>Kzeus Home automation</a></h3>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</section> -->
<div class="curated-solution page-section" id="Solution">
<div class="container">
    <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12 curatedalign">
                    <div class="bottomline-curated" id="contactnow" data-aos="fade-up" data-aos-duration="1000">
                        <h3>Curated Solutions for Different Industries</h3>
                    </div>
                    <p data-aos="fade-up" data-aos-duration="1300"> We started our journey to make the solution in different Domain by understanding there
                        necessity.</p>
                
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
                <img data-aos="fade-up" data-aos-duration="1600" src="assets/images/solution-pic.svg" width="100%" height="auto">
            </div>
        </div>
    </div>
</div>
<div class="container page-section" id="Contact">
    <div class="attfets" data-aos="fade-up" data-aos-duration="1000">
        <div class="bottomline-msg">
            <h3>Send message</h3>
        </div>
        <div class="send-msg-bg">
            <p class="pad-from-msg">We loved to hear from you anytime</p>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="form-group">
                        <input type="text" class="msg-details" formControlName="name" name="fname" placeholder="Your Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Name is required</div>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="form-group">
                        <input type="text" class="msg-details" name="email" formControlName="email" placeholder="Email Address*" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="form-group">
                        <input type="text" class="msg-details" formControlName="phonenumber" name="phone" placeholder="Phone Number" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
                        <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                            <div *ngIf="f.phonenumber.errors.required">Phone Number is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="form-group">
                        <input type="text" class="msg-details" name="company" formControlName="companyname" placeholder="Your Company" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }">
                    <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                        <div *ngIf="f.companyname.errors.required">Company Name is required</div>
                    </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12">
                   <div class="form-group">
                    <textarea class="your-msg" formControlName="description" name="company" rows="4" cols="50" placeholder="Message" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback1">
                        <div *ngIf="f.description.errors.required">Description is required</div>
                    </div>
                   </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <div class="send-msg"><button class="send-msg-button" type="submit">Send Message</button></div>
                </div>
            </div>
            </form>
        </div>
    </div>
</div>

<footer class="footer-bg">
    <div class="image-layer">
        <figure class="image-1 rotate-me"><img src="assets/images/wheel.svg" alt=""></figure>
    </div>
        <div class="container footer-position">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <a href="index.html" class="footer-logo" data-aos="fade-up" data-aos-duration="300"><img src="assets/images/logo.png" alt="kzeus" class="img-size"></a>
                </div>
                <div class="col-md-6 col-sm-6">
                    <ul class="footer-ul" data-aos="fade-up" data-aos-duration="600">
                        <li class="footer-li"><a href="https://www.facebook.com/kzeusKIoT" target="_blank"><i class="fab fa-facebook-f footer-li-1"></i></a>
                        </li>
                        <li class="footer-li"><a href="https://twitter.com/KevellsKzeus" target="_blank"><i class="fab fa-twitter footer-li-2"></i></a>
                        </li>
                        <li class="footer-li"><a href="https://www.instagram.com/kzeuskevells/" target="_blank"><i class="fab fa-instagram footer-li-3"></i></a>
                        </li>
                        <li class="footer-li"><a href="https://www.linkedin.com/company/73017400" target="_blank"><i class="fab fa-linkedin-in footer-li-4"></i></a>
                        </li>
                        <li class="footer-li"><a href="https://in.pinterest.com/kzeuskevells/" target="_blank"><i class="fab fa-pinterest-p footer-li-5"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-list-ul">
                <div class="row">
                    <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
                        <h3>Solutions</h3>
                        <ul class="li-color">
                            <li><a href="javascript:void(0)">Smart Building</a></li>
                            <li><a href="javascript:void(0)">Automation</a></li>
                            <li><a href="javascript:void(0)">Home Automation</a></li>
                            <li><a href="javascript:void(0)">Agriculture</a></li>
                            <li><a href="javascript:void(0)">Hospitality</a></li>
                            <li><a href="javascript:void(0)">Health</a></li>
                            <li><a href="javascript:void(0)">Industry</a></li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1300">
                        <h3>Contact Us</h3>
                        <h4>Customer support</h4>
                        <ul class="li-color">
                            <li><a href="mailto:info@kzeus.io">info@kzeus.io</a></li>
                            <li><a href="mailto:support@kzeus.io">support@kzeus.io</a></li>
                        </ul>
                        <h4>Business development</h4>
                        <ul class="li-color">
                            <li><a href="mailto:sales@kzeus.io">sales@kzeus.io</a></li>
                            <li><a href="mailto:service@kzeus.io">service@kzeus.io</a></li>
                        </ul>
                        <h4>Hot Line</h4>
                        <ul class="li-color">
                            <li><a href="javascript:void(0)">+91 70102 12129</a></li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1600">
                        <h3>Locations</h3>
                        <ul class="loc-pad">
                            <li class="flag-ind"><img src="assets/images/india.svg">
                                <h4>India</h4>
                            </li>
                            <li>5th Street, Poriyalar Nagar,</li>
                            <li>Tiruppalai, City Union Bank 1st Floor,</li>
                            <li>Madurai, Tamil Nadu 625014.</li>
                        </ul>
                        <ul class="loc-pad">
                            <li>3A, CG2 Ashwin Adisaya flats,</li>
                            <li>Ponnurangam street,</li>
                            <li>Puzhuthivakkam, Chennai-600091.</li>
                        </ul>
                        <ul class="loc-pad">
                            <li>UpStart Co-working Space,</li>
                            <li>14/3A 1A4 OPP Brigade Cosmopolis,</li>
                            <li>Balaraj's Arcade, 4th Floor,</li>
                            <li>Whitefield Main Road,</li>
                            <li>Whitefield, Bangalore, Karnataka - 560066.</li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1900">
                        <ul class="loc-pad loc-mar">
                            <li class="flag-us"><img src="assets/images/united-states.svg">
                                <h4>United States</h4>
                            </li>
                            <!-- <li>10295 Taliesin Dr #305,</li>
                            <li>Englewood, Colorado 80112.</li> -->
                            <li>22553 E Piccolo Way Aurora Colorado 80016</li>
                        </ul>
                        <ul class="loc-pad">
                            <li class="flag-sing"><img src="assets/images/singapore.svg">
                                <h4>Singapore</h4>
                            </li>
                            <li>1 Coleman street #10-06</li>
                            <li>The Adelphi Singapore 179803.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer-copyrights">
                <p>Copyright &copy; 2023 <a href="index.html" class="rights-link">Kzeus</a>. All rights reserved</p>
                <p><a [routerLink]="['/privacypolicy']" class="rights-link">Privacy policy</a></p>
            </div>
            <!-- <a id="#top" onclick="scrollToTop()" class="top-position">
                <i class="fas fa-chevron-up icon-top"></i>
                <label>Top</label>
            </a> -->
            <!-- Back to top button -->
            <a href="#" id="scroll" style="display: none;">
                <i class="fas fa-chevron-up icon-top"></i>
                <label>Top</label>
            </a>
        </div>
    <div class="footer-shape" style="background-image: url(assets/images/footer-btm.svg);"></div>
</footer>
