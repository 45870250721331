import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ComponentComponent } from './pages/component/component.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';


const routes: Routes = [
  { path: '', component: ComponentComponent },
  { path: 'blogs', component: BlogComponent },
  { path: 'blog/:id', component: BlogDetailsComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
