<header id="myHeader" class="blog-header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg">
                    <a class="navbar-brand" href="index.html">
                      <img src="assets/images/logo.png" class="logo-light" alt="logo">
                    </a>
                    <button class="navbar-toggler" type="button">
                      <span class="menu-lines"><span></span></span>
                    </button>
                    <div class="collapse navbar-collapse" id="mainNavigation">
                      <ul class="navbar-nav ml-auto">
                        <li class="nav__item">
                          <a href="" data-toggle="dropdown" class="nav__item-link blog-menu">Home</a>
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#about" data-toggle="dropdown" class="nav__item-link blog-menu">About</a>
                          
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#WhyKzeus" data-toggle="dropdown" class="nav__item-link blog-menu">Why Kzeus</a>
                          
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#Features" data-toggle="dropdown" class="nav__item-link blog-menu">Features</a>
                          
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#Solution" data-toggle="dropdown" class="nav__item-link blog-menu">Solution</a>
                          
                        </li><!-- /.nav-item -->
                        <li class="nav__item">
                            <a [routerLink]="['/blogs']" data-toggle="dropdown" class="nav__item-link active blog-menu">Blog</a>
                            
                          </li><!-- /.nav-item -->
                        <li class="nav__item">
                          <a href="#Contact" class="nav__item-link blog-menu">Contact</a>
                        </li><!-- /.nav-item -->
                      </ul><!-- /.navbar-nav -->
                      <button class="close-mobile-menu d-block d-lg-none"><i class="fas fa-times"></i></button>
                    </div><!-- /.navbar-collapse -->
                </nav><!-- /.navabr -->
            </div>
        </div>
    </div>
    
  </header><!-- /.Header -->
<div class="container blog-section">
    <div class="row">
        <div class="col-12">
            <h3 class="inner-title">Blog</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-12" *ngFor="let blogs of BlogDetails">
            <div class="blog-list">
                <img src="{{blogs.imageUrl}}" width="100%" height="auto">
                <h3>{{blogs.Title}}</h3>
                <p>{{blogs.short_line}}</p>
                <a [routerLink]="'/blog/'+ blogs.routUrl" class="read-p">Read more</a>
            </div>
        </div>
        <!-- <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="blog-list">
                <img src="assets/images/blog2.jpg" width="100%" height="auto">
                <h3>Kzeus Agriculture Automation Product</h3>
                <p>We know that the time that IoT was considered a passing trend has disappeared fast.</p>
                <a [routerLink]="['/blogsdetails']" class="read-p">Read more</a>
            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="blog-list">
                <img src="assets/images/blog3.jpg" width="100%" height="auto">
                <h3>Kzeus Product for Building Automation</h3>
                <p>As a professional, I have certain things to share about Kzeus product; I enjoy this product thoroughly.</p>
                <a [routerLink]="['/blogsdetails']" class="read-p">Read more</a>
            </div>  
        </div>                  
        <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="blog-list">
                    <img src="assets/images/blog4.jpg" width="100%" height="auto">
                    <h3>Kzeus Industrial Automation Product</h3>
                    <p>While things have been busy schedule for people in home due to Covid 19 lockdown as</p>
                    <a [routerLink]="['/blogsdetails']" class="read-p">Read more</a>
                </div>
            </div> -->
        </div>
        </div>
        <footer class="footer-bg">
            <div class="image-layer">
                <figure class="image-1 rotate-me"><img src="assets/images/wheel.svg" alt=""></figure>
            </div>
                <div class="container footer-position">
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <a href="index.html" class="footer-logo" data-aos="fade-up" data-aos-duration="600"><img src="assets/images/logo.png" alt="kzeus" class="img-size"></a>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <ul class="footer-ul" data-aos="fade-up" data-aos-duration="600">
								<li class="footer-li"><a href="https://www.facebook.com/kzeusKIoT" target="_blank"><i class="fab fa-facebook-f footer-li-1"></i></a>
								</li>
								<li class="footer-li"><a href="https://twitter.com/KevellsKzeus" target="_blank"><i class="fab fa-twitter footer-li-2"></i></a>
								</li>
								<li class="footer-li"><a href="https://www.instagram.com/kzeuskevells/" target="_blank"><i class="fab fa-instagram footer-li-3"></i></a>
								</li>
								<li class="footer-li"><a href="https://www.linkedin.com/company/73017400" target="_blank"><i class="fab fa-linkedin-in footer-li-4"></i></a>
								</li>
								<li class="footer-li"><a href="https://in.pinterest.com/kzeuskevells/" target="_blank"><i class="fab fa-pinterest-p footer-li-5"></i></a>
								</li>
							</ul>
                        </div>
                    </div>
                    <div class="footer-list-ul">
                        <div class="row">
                            <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
                                <h3>Solutions</h3>
                                <ul class="li-color">
                                    <li><a href="javascript:void(0)">Smart Building</a></li>
                                    <li><a href="javascript:void(0)">Automation</a></li>
                                    <li><a href="javascript:void(0)">Home Automation</a></li>
                                    <li><a href="javascript:void(0)">Agriculture</a></li>
                                    <li><a href="javascript:void(0)">Hospitality</a></li>
                                    <li><a href="javascript:void(0)">Health</a></li>
                                    <li><a href="javascript:void(0)">Industry</a></li>
                                </ul>
                            </div>
                            <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1300">
                                <h3>Contact Us</h3>
                                <h4>Customer support</h4>
                                <ul class="li-color">
                                    <li><a href="mailto:info@kzeus.io">info@kzeus.io</a></li>
                                    <li><a href="mailto:support@kzeus.io">support@kzeus.io</a></li>
                                </ul>
                                <h4>Business development</h4>
                                <ul class="li-color">
                                    <li><a href="mailto:sales@kzeus.io">sales@kzeus.io</a></li>
                                    <li><a href="mailto:service@kzeus.io">service@kzeus.io</a></li>
                                </ul>
                                <h4>Hot Line</h4>
                                <ul class="li-color">
                                    <li><a href="javascript:void(0)">+91 70102 12129</a></li>
                                </ul>
                            </div>
                            <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1600">
                                <h3>Locations</h3>
                                <ul class="loc-pad">
                                    <li class="flag-ind"><img src="assets/images/india.svg">
                                        <h4>India</h4>
                                    </li>
                                    <li>5th Street, Poriyalar Nagar,</li>
									<li>Tiruppalai, City Union Bank 1st Floor,</li>
									<li>Madurai, Tamil Nadu 625014.</li>
                                </ul>
                                <ul class="loc-pad">
                                    <li>3A, CG2 Ashwin Adisaya flats,</li>
                                    <li>Ponnurangam street,</li>
                                    <li>Puzhuthivakkam, Chennai-600091.</li>
                                </ul>
                                <ul class="loc-pad">
                                    <li>UpStart Co-working Space,</li>
                                    <li>14/3A 1A4 OPP Brigade Cosmopolis,</li>
                                    <li>Balaraj's Arcade, 4th Floor,</li>
                                    <li>Whitefield Main Road,</li>
                                    <li>Whitefield, Bangalore, Karnataka - 560066.</li>
                                </ul>
                            </div>
                            <div class="col-md-6 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1900">
                                <ul class="loc-pad loc-mar">
                                    <li class="flag-us"><img src="assets/images/united-states.svg">
                                        <h4>United States</h4>
                                    </li>
                                    <li>22553 E Piccolo Way Aurora Colorado 80016</li>
                                    <!-- <li>10295 Taliesin Dr #305,</li>
                                    <li>Englewood, Colorado 80112.</li> -->
                                </ul>
                                <ul class="loc-pad">
                                    <li class="flag-sing"><img src="assets/images/singapore.svg">
                                        <h4>Singapore</h4>
                                    </li>
                                    <li>1 Coleman street #10-06</li>
                                    <li>The Adelphi Singapore 179803.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="footer-copyrights">
                        <p>Copyright &copy; 2023 <a href="index.html" class="rights-link">Kzeus</a>. All rights reserved</p>
                        <p><a href="privacy.html" class="rights-link">Privacy policy</a></p>
                    </div>
                    <!-- <a id="#top" onclick="scrollToTop()" class="top-position">
                        <i class="fas fa-chevron-up icon-top"></i>
                        <label>Top</label>
                    </a> -->
                    <!-- Back to top button -->
                    <a href="#" id="scroll" style="display: none;">
                        <i class="fas fa-chevron-up icon-top"></i>
                        <label>Top</label>
                    </a>
                </div>
            <div class="footer-shape" style="background-image: url(assets/images/footer-btm.svg);"></div>
        </footer>
