import { Component, OnInit } from '@angular/core';
import bloginfo from '../../../assets/blogs.json';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  BlogsDetails = bloginfo;
  filteredblog = bloginfo;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.filteredblog = this.BlogsDetails.filter((x: { routUrl: string | null; })=>x.routUrl==id)
  }

}
