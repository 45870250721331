
<router-outlet></router-outlet>

<script>
  var jump=function(e)
{
   if (e){
       e.preventDefault();
       var target = $(this).attr("href");
   }else{
       var target = location.hash;
   }

   $('html,body').animate(
   {
       scrollTop: $(target).offset().top
   },2000,function()
   {
       location.hash = target;
   });

}


$(document).ready(function()
{
    $('.blog-menu.a[href^=#]').bind("click", jump);

 
      
    
});
</script>
<div class="go-top" *ngIf="isShow" (click)="gotoTop()">
  <i class="fas fa-wifi"></i>
  <i class="fas fa-wifi"></i>
</div>
<div class="go-top1">
  <a href="https://api.whatsapp.com/send?phone=+9199442 10789" target="_blank"><i class="fab fa-whatsapp"></i></a>
</div>
