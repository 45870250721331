import { HttpClientModule } from '@angular/common/http';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ApiServiceService } from './api-service.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentComponent } from './pages/component/component.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common'; 
import {  NgxSlickJsModule } from 'ngx-slickjs'

@NgModule({
  declarations: [
    AppComponent,
    ComponentComponent,
    BlogComponent,
    BlogDetailsComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSlickJsModule.forRoot()
    
  ],
  providers: [ApiServiceService,
 {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule { }
